
$museoSlab: 'Museo Slab';
$trebuchet: 'Trebuchet MS', 'Lato', sans-serif;
$openSans: 'Open Sans', sans-serif;

$co-blue: #001970;
$co-yellow: #FFD100;

$co-black: #000;
$co-dark-gray: #424242;
$co-medium-gray: #B7B7B7;
$co-med-light-gray: #DBDBDB;
$co-light-gray: #F5F5F5;
$co-white: #FFF;
$co-bg-gray: #EDEDED;

$co-primary: #2E6AD1;
$co-secondary: #FFEF74;

//addtl colors from their branding website
$co-red: #c3002f;
$co-purple: #6d3a5d;
$co-green: #245d38;
$co-olive: #7a853b;
$co-teal: #35647e;
