@import './vars.module.scss';

.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.header {
  height: 64px;
  background-color: $white;
  display: flex;
  align-items: center;
}

.headerContent {
  margin: 0 !important;
  display: flex;
  align-items: center;
  position: relative;
}

.headerLogo {
  height: 51px;
  margin-right: 16px;
  @media screen and (max-width: 1023px) {
    height: 32px;
  }
}

.headerSecondLogo {
  padding-top: 4px;
  height: 40px;
}

.header__alt {
  height: 90px;
  z-index: 10;

  @media screen and (max-width: 767px) {
    height: 60px;
    position: sticky;
    top: 0;
  }

  &__mobileMenuOpen {
    @media screen and (max-width: 767px) {
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 3;
    }
  }
}

.header__borderBottom {
  border-bottom: solid 1px lighten($medium-gray, 19%);
}

.newHeroLanguagesDesktop {
  @media screen and (max-width: 959px) {
    display: none;
  }
}

.footer {
  background-color: $navy;
  color: $white;
  position: relative;

  .footerContent {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 14px;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .footerLeft {
      @media screen and (min-width: $breakpoint-sm) {
        flex-direction: row;
      }
    }

    .footerRight {
      justify-content: center;
      text-align: center;

      @media screen and (min-width: $breakpoint-sm) {
        align-items: flex-end;
        text-align: right;

        .footerText {
          max-width: 370px;
        }
      }
    }
  }

  .footerContentBottom {
    margin: 0 !important;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .footerImages {
    height: 70px;

    @media screen and (max-width: $breakpoint-sm) {
      display: flex;
      justify-content: center;
    }
  }

  .footerText {
    font-size: 14px;
    line-height: 24px;
    color: $white;

    a {
      color: $white;
    }

    @media screen and (max-width: $breakpoint-sm) {
      margin: 0 auto;
      text-align: center;
    }
  }

  .officialText {
    padding-top: 9px;
    margin: 0;

    @media screen and (max-width: $breakpoint-sm) {
      padding-bottom: 18px;
      text-align: center;
      margin: 0 auto;
    }
  }

  .footerLogo {
    height: 33px;
    height: 70px;

    &:nth-child(odd) {
      padding-right: 10px;
    }
  }

  .footerCopy {
    font-size: 12px;
    padding: 5px 0 2px;
    max-width: 370px;
    margin: 0 auto;

    @media screen and (max-width: $breakpoint-sm) {
      text-align: center;
    }
  }
}

.separator {
  width: 100%;
  border-top: solid 1px $medium-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin-top: 3px;
  margin-bottom: 8px;
}

.separatorBold {
  width: 100%;
  border-top: solid 3px $light-gray;
  border-left: none;
  border-right: none;
  border-bottom: none;
  margin: 0;
}

.link,
.link:visited {
  color: $action-blue !important;
  font-weight: 700;
  text-decoration: underline;
}

.link:hover,
.link:active {
  color: $action-blue-hover;
  cursor: pointer;
}

.linkWhite,
.linkWhite:visited {
  color: $white;
  font-weight: 700;
  text-decoration: underline;
}

.linkWhite:hover,
.linkWhite:active {
  color: darken($white, 10%);
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.descriptor {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
}

.descriptorBold {
  font-size: 14px;
  line-height: 21px;
  color: $dark-gray;
  font-weight: 700;
}

.formCoachingText {
  font-size: 12px;
  line-height: 18px;
  color: $dark-gray;
}

.infoIcon {
  width: 20px;
  height: 20px;
}

.infoIcon__container {
  padding-left: 9px;
}

.hidden {
  display: none;
}

.searchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all 0.35s;

  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }

  input {
    padding: 12px 0 13px 0 !important;
    background-color: $white;
    width: 0;
    transition: all 0.35s;

    &:focus {
      padding: 12px 11px 13px !important;
      width: 200px;
      background-color: $light-gray;
    }

    @media screen and (max-width: 767px) {
      padding: 11px 11px 9px !important;
    }
  }

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.inputFocused {
  background-color: $light-gray !important;
  margin-left: 11px;
}

.searchFieldLabel {
  cursor: pointer;
}

.mobileSearchInputRoot {
  margin-bottom: 0 !important;
  background-color: $white !important;
  transition: all 0.35s;
  position: absolute !important;
  left: -20px;
  z-index: 3;

  svg {
    fill: $primary-color-main;
    font-size: 26px;
  }

  input {
    padding: 0 !important;
    background-color: $white;
    /*width: 0;*/
    transition: all 0.35s;
    position: absolute;

    &:focus {
      padding: 12px 32px 13px !important;
      width: calc(100vw - 94px);
      background-color: $light-gray;

      @media screen and (max-width: 599px) {
        width: calc(100vw - 79px);
      }
    }
  }

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.mobileSearchButton {
  z-index: 1000;

  @media screen and (min-width: 768px) {
    display: none !important;
  }
}

.headerLeftCell {
  @media screen and (max-width: 767px) {
    position: relative;
    left: -16px;
  }
}

.browserBanner {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 12px 16px;
  background-color: $secondary-color-main;
}

.envBanner {
  text-align: center;
  padding: 12px 16px;
  background-color: $co-bg-gray;
  color: $co-dark-gray;

  a {
    color: $co-dark-gray;
    &:visited {
      color: $co-dark-gray;
    }
    &:hover,
    &:active {
      color: darken($co-dark-gray, 10%);
    }
  }

  span {
    padding-right: 0.25em;
  }
}

.hamburgerButton {
  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 4px;
  }

  &__text {
    color: $action-blue;
    text-transform: uppercase;
    font-size: 9px;
    font-weight: 500;
    text-align: center;
    padding-top: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

.mobileMenu {
  position: absolute;
  top: 60px;
  left: 0;
  z-index: 100;
  background: $navy;
  width: 100vw;
  opacity: 0;
  pointer-events: none;
  transition: all 0.5s;

  &__link {
    font-weight: 400;
    font-size: 14px;
  }

  &__languagesContainer {
    border-bottom: solid 1px $action-blue;
    padding: 16px;
  }
}

.mobileMenu ul {
  padding: 14px 16px 20px;
  margin: 0;

  &:not(:last-child) {
    border-bottom: solid 1px $action-blue;
  }
}

.mobileMenu__item {
  list-style: none;

  padding: 6px 0;
  border-bottom: 1px solid rgba($navy, 0.4);
}

.mobileMenu__item a {
  text-transform: uppercase;
  font-size: 22px;
  color: $white;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.mobileMenu__item.noTextTransform a {
  text-transform: none !important;
}

.mobileMenu__item i {
  padding-right: 10px;
}

.mobileMenuOpen {
  position: fixed;
  opacity: 1;
  left: 0;
  overflow: scroll;
  pointer-events: all;
}
.creditsButton {
  padding: 12px;
  text-align: center;
}

.closeButton {
  float: right;
  position: relative;
  top: 0;
  padding: 40px;
}
.topMobile {
  @media screen and (max-width: 767px) {
    top: 4px !important;
    margin-left: 20px !important;
  }

  @media screen and (min-width: 768px) {
    margin-left: 10px !important;
  }
}
