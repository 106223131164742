@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Roboto:wght@500&family=Source+Serif+Pro:wght@400;700&family=Lato:ital,wght@0,700;1,400;1,700&display=swap');
@import './vars.module.scss';

@font-face {
  font-family: 'Museo Slab';
  src: local('Museo_Slab_500_2'), url('../assets/fonts/Museo_Slab_500_2-webfont.woff2') format('woff2'),
       url('../assets/fonts/Museo_Slab_500_2-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Museo Slab';
  src: local('Museo_Slab_500italic'), url('../assets/fonts/Museo_Slab_500italic-webfont.woff2') format('woff2'),
       url('../assets/fonts/Museo_Slab_500italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

body {
  min-height: 100vh;
  font-family: $body-font;
  background-color: $background-color-default;
  
  a {
    color: $primary-color-main;

    &:hover {
      color: $primary-color-dark;
    }
  }
}