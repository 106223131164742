@import './vars-co.module.scss';

//strings & other global variables
$organization-name: 'Colorado Department of Labor and Employment';
$header-logo-href: 'https://cdle.colorado.gov/';
$logo-header-url: '/images/logo-header-co.png';
$logo-header-height: 51px;
$logo-footer-url: '/images/logo-footer-co.png';
$logo-footer-height: 70px;
$footer-email: 'feedback@careertrailguide.co';
$state-seal-href: 'https://cdle.colorado.gov/';

//mui breakpoints
$breakpoint-xs: 0px;
$breakpoint-sm: 600px;
$breakpoint-md: 960px;
$breakpoint-lg: 1280px;
$breakpoint-xl: 1920px;

//typefaces
$header-font: $trebuchet;
$body-font: $openSans;
$font-family: $openSans;

//colors
$black: $co-black;
$dark-gray: $co-dark-gray;
$medium-gray: $co-medium-gray;
$mid-light-gray: $co-med-light-gray;
$light-gray: $co-light-gray;
$white: $co-white;

//util colors
$background-color-default: $co-bg-gray;
$navy: $co-blue; //header & footer color
$header-accent-1: $co-yellow;
$header-accent-2: $co-yellow;
$header-accent-3: $co-yellow;
$header-accent-4: $co-yellow;

$tag-career: lighten($co-purple, 60%); //light-red #FCE6E6
$tag-job: lighten($co-olive, 50%); //light-green #EBF8E8
$tag-skill: #d4e4ed;

$card-accent-career: lighten($co-red, 5%);
$card-accent-job: lighten($co-green, 15%);
$card-accent-training: lighten($co-purple, 15%); //#EB843B
$card-accent-search: lighten($co-green, 20%); //#0E6E10
$card-accent-search-yellow: $co-yellow; //#FCCB00
$card-accent-experience: lighten($co-teal, 25%); //#8FB9E2

$primary-color-main: $co-primary;
$primary-color-dark: $co-blue;
$accent-color-main: $co-yellow;
$secondary-color-main: $co-secondary;
$secondary-color-dark: $co-yellow;

$error-color-main: $co-red;
$success-green: $co-green;
$progress-green: lighten($co-green, 20%);
$action-blue: $co-primary;
$action-blue-hover: $co-blue;
$filter-tag: lighten($action-blue, 40%);
$filter-tag-hover: lighten($action-blue, 45%);

$red: $co-red; //used in env banner
$teal: $co-teal; //used for login button
$blue: lighten($co-teal, 30%); //used by chat widget #8FB9E2


/* variables that are needed in js (either by the theme file or to apply inline styling) */
:export {
  /* strings & other global variables */
  organizationName: $organization-name;
  headerLogoHref: $header-logo-href;
  logoHeaderUrl: $logo-header-url;
  footerEmail: $footer-email;
  logoFooterUrl: $logo-footer-url;
  stateSealHref: $state-seal-href;

  /*style variables */
  headerFont: $header-font;
  bodyFont: $body-font;
  black: $black;
  darkGray: $dark-gray;
  mediumGray: $medium-gray;
  lightGray: $light-gray;
  white: $white;
  backgroundColorDefault: $background-color-default;
  primaryColorMain: $primary-color-main;
  primaryColorDark: $primary-color-dark;
  accentColorMain: $accent-color-main;
  secondaryColorMain: $secondary-color-main;
  secondaryColorDark: $secondary-color-dark;
  errorColorMain: $error-color-main;
  progressGreen: $progress-green;
  successGreen: $success-green;
  cardAccentExperience: $card-accent-experience;
  actionBlue: $action-blue;
  actionBlueHover: $action-blue-hover;
  tagBackgroundColor: $white;
  tagCareer: $tag-career;
  tagJob: $tag-job;
  tagJobHover: darken($tag-job, 4%);
  tagSkill: $tag-skill;
  tagSkillHover: darken($tag-skill, 5%);
  filterTag: $filter-tag;
  filterTagHover: $filter-tag-hover;
  midLightGray: $co-med-light-gray;

  // Recommendations Card
  cardAccentCareer: $card-accent-career;
  cardAccentJob: $card-accent-job;
  cardAccentTraining: $card-accent-training;

  // Chat
  chatTitleAvatar: '/images/plumeria.png';
  chatProfileAvatar: '/images/bot-profile.png';
  chatLogo: "/images/bot-profile.png";
}