@import './vars.module.scss';

.content {
  padding: 30px 0;
}
.careersTitleFilters {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
.titleFilters {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.bottomBar {
  padding-top: 16px;
  padding-bottom: 19px;
  display: flex;
  justify-content: space-between;
}
.backToTop {
  font-size: 12px;
  line-height: 18px;
  text-decoration: none;
  color: $dark-gray;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.closeButtonNoHeight {
  margin-top: 10px;
  height: 31px !important;
}
.backToTopImg {
  width: 34px;
  height: 34px;
  margin-left: 7px;
}
.backToTopPlaceholder {
  @media screen and (max-width: 599px) {
    display: none;
  }
}
.careersFiltersContainer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 14px;
  div:not(:first-of-type) {
    margin-top: 16px;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 0;
    padding-bottom: 0;
    top: -13px;
    div:not(:first-of-type) {
      margin-left: 10px;
      margin-top: 0;
    }
  }
}
.autocompleteContainer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 14px;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    top: -13px;
  }
}
.autocompleteMultipleContainer {
  & > div:last-child {
    margin-top: 16px;
  }
  @media screen and (min-width: 768px) {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 1fr;

    & > div:last-child {
      margin-top: 0;
    }
  }
}
.autocompleteModernContainer {
  & > div:last-child {
    margin-top: 16px;
  }
  @media screen and (min-width: 768px) {
    grid-gap: 16px;
    display: grid;
    grid-template-columns: 1fr 114px;

    & > div:last-child {
      margin-top: 0;
    }
  }
}
.stepperContainer {
  position: relative;
  padding-top: 10px;
  padding-bottom: 14px;
  @media screen and (min-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
    top: -13px;
  }
}
.printShareToolbar__right {
  display: flex;
  justify-content: center;
  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }
}
.printShareToolbar__disclaimer {
  max-width: 500px;
  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1024px) {
    max-width: 840px;
  }
}
.printShareToolbar {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }

  &__buttons {
    display: flex;
    &__button {
      position: relative;
      padding-left: 9px;
      &:hover {
        cursor: pointer;
      }
      span {
        color: $action-blue;
        padding-right: 4px;
        font-weight: 700;
        text-decoration: underline;
      }
      display: flex;
    }
  }
}
.printShareToolbar__buttons_icon {
  font-size: 18px !important;
  position: relative;
  top: -2px;
}
.jobSearchActivityMain {
  background-color: $white;
  padding: 26px 30px 32px;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}
.jobSearchActivityMain__half {
  display: flex;
  @media screen and (min-width: 768px) {
    padding-right: 20px;
    width: 50%;
  }
}
.firstHalf {
  flex-direction: column;
}
.jobSearchActivityMain__list {
  list-style-image: url('/assets/images/Circle.svg');
  li p {
    margin-bottom: 0;
  }
}
.jobSearchActivityMain__cards {
  display: flex;
  flex-direction: column;
}
.requiredActivityCard {
  display: flex;
  border: 1px solid $medium-gray;
  padding: 24px 35px 24px 30px;
  box-sizing: border-box;
  justify-content: space-between;
  &:first-child {
    margin-bottom: 22px;
  }
}
.requiredActivityCard__text {
  padding-right: 16px;
}
.requiredActivityCard__title {
  color: $black;
  font-size: 14px;
  font-weight: 700;
}
.requiredActivityCard__description {
  color: $dark-gray;
  font-size: 12px;
}
.requiredActivityCard__circle {
  width: 111px;
  height: 111px;
}
.circleCompleted {
  color: $black;
  font-size: 24px;
  font-weight: 700;
  @media screen and (max-width: 767px) {
    font-size: 18px;
  }
}
.circleTotal {
  color: $black;
  font-weight: 400;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    font-size: 10px;
  }
}
.educationForm {
  max-width: 600px;
}
.experienceResume {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }

  &__card {
    display: inline-block;
    padding: 25px 10px;

    form {
      button[type='submit'] {
        display: inline-block;
        margin-left: auto;
      }
    }

    @media screen and (min-width: 768px) {
      padding: 10px 25px;
      width: 50%;
      min-height: 400px;
      display: flex;
      flex-direction: column;

      & > h5,
      & > h4 {
        flex: 0;
      }

      & > form {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        & > div:first-child {
          // flex: 1 0 400px;
          margin: 0;
          max-height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          @media screen and (min-width: 1080px) {
            max-height: 300px;
          }
        }
      }

      &:first-child {
        & > form {
          & > div:first-child {
            flex: 1 0 400px;

            @media screen and (min-width: 1080px) {
              flex: 1 0 300px;
            }
          }
        }
      }

      &:last-child {
        border-left: 1px solid $medium-gray;

        & > form {
          & > div:first-child {
            flex-basis: 100%;
            min-height: 400px;

            @media screen and (min-width: 1080px) {
              min-height: 300px;
            }
          }
        }
      }
    }
  }
}
