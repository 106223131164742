@import './vars.module.scss';

.rcw-widget-container {
  max-width: 321px !important;
  height: auto !important;
  max-height: 80vh;
  pointer-events: none;
  z-index: 1299 !important;
}

.rcw-send {
  display: none;
}

.rcw-header {
  background-color: #2E6AD1 !important;
  padding: 0 !important;
  border-radius: 4px 4px 0 0 !important;
}

.rcw-conversation-container {
  border-radius: 4px !important;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25) !important;
  pointer-events: all;
}

.rcw-launcher {
  background-color: #2E6AD1 !important;
  pointer-events: all;
  box-shadow: none !important;
  @media screen and ( max-width: 1023px ) {
    width: 55px !important;
    height: 55px !important;
  }
}

.minimize-chat-button:hover {
  cursor: pointer;
}

.minimize-chat-button {
  pointer-events: all;
  position: absolute;
  right: 10px;
  top: 8px;
  background-color: transparent;
  border: none;
  background-image: url(/images/minimize.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 25px;
  width: 30px;
}

.rcw-hide-sm {
  display: block !important;
}

.rcw-client .rcw-message-text {
  color: white;
  background-color: #2E6AD1 !important;
  border-radius: 18px 18px 0 18px !important;
  padding: 8px 14px !important;
  
}

.rcw-message-text {
  ul {
    padding-left: 20px !important;
  }
}

.rcw-response .rcw-message-text {
  color: $dark-gray;
  background-color: $white !important;
  border-radius: 18px 18px 18px 0 !important;
  padding: 8px 14px !important;
  border: solid 1px #e0e0e0;
}

.rcw-response p:not(:last-child) {
  padding-bottom: 10px;
}

.rcw-title {
  font-size: 17px !important;
  font-weight: 400;
  padding: 10px 12px !important;
  text-align: left;
  font-weight: 600;

  .avatar {
    position: relative;
    left: 2px;
  }
}

.rcw-messages-container {
  background-color: $light-gray !important;
  max-height: 410px !important;
}

.rcw-message {
  align-items: flex-end;
}

.rcw-message a {
  text-decoration: underline;
  color: $blue;
  font-weight: 600;
}

.rcw-message a:hover {
  color: darken($blue, 10%);
}

.rcw-avatar {
  position: relative;
  bottom: 16px;
  left: 4px;
}

.rcw-sender {
  background-color: $white;
  border-radius: 0 0 4px 4px !important;
  box-sizing: border-box;
}

.rcw-sender .rcw-new-message {
  font-size: 16px;
  color: $dark-gray;
}

.rcw-close-button {
  background-color: #c6daee !important;
  padding-top: 3px;
  top: 8px !important;
  border-radius: 4px;
  width: 36px !important;
}

.rcw-close-button img {
  width: 16px !important;
}

.rcw-close-button ~span {
  display: none;
}

.rcw-widget-container {
  @media screen and ( max-width: 800px ) {
    margin: 0 !important;
    .rcw-conversation-container + .rcw-launcher {
      display: none;
    }

    .rcw-launcher {
      display: block;
    }
  }
  .rcw-launcher {
    background: {
      image: url(/images/question-mark-launcher.png);
      repeat: no-repeat;
      position: 50%;
      size: 50px;
    };
    @media screen and ( max-width: 1023px ) {
      background: {
        image: url(/images/question-mark-launcher.png);
        repeat: no-repeat;
        position: 50%;
        size: 41px;
      };
    }

    .rcw-open-launcher {
      display: none;
    }
  }

  .rcw-conversation-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-flow: column nowrap;
    height: calc(80vh - 100px);

    .rcw-header,
    .rcw-messages-container,
    .quick-buttons-container,
    .rcw-sender {
      flex: 0 0 auto;
      max-height: initial !important;
      min-height: 0px;
      min-width: 0px;
      width: 100%;
    }

    .rcw-messages-container {
      height: auto;
      flex: 1 1 0px;
    }

    .rcw-header,
    .quick-buttons-container,
    .rcw-sender {
      height: auto;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
    }
  }
}
.chat-link, .chat-link:visited {
  color: $action-blue !important;
  font-weight: 700 !important;
  text-decoration: underline;
}
.chat-link:hover, .chat-link:active {
  color: $action-blue-hover;
  cursor: pointer;
}